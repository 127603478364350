import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11')
];

export const server_loads = [0,2];

export const dictionary = {
		"/(app)": [3],
		"/(app)/back-testing": [6],
		"/(app)/gallery": [~7],
		"/(app)/(history)/graphs": [4,[2]],
		"/(app)/(history)/history": [5,[2]],
		"/(auth)/login": [10],
		"/(app)/open-trades": [8],
		"/(app)/pending-orders": [9],
		"/(auth)/register": [~11]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';